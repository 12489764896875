
import {
  defineComponent,
  reactive,
  onMounted,
  toRefs,
  onBeforeUnmount,
  watch,
  ref,
  computed,
  nextTick
} from 'vue'
import { tHeaderList, listHK, listUS, listA, S5LIST, B5LIST } from './data'
import { ElMessage, ElMessageBox } from 'element-plus'
import up from '@/assets/images/home/up.svg'
import dropdown from './component/dropdown.vue'
import dropdownInput from './component/dropdownInput.vue'
import confirmPopup from './component/confirmPopup.vue'
import timeoutDialog from '@/components/timeout_dialog/index.vue'
import tipcom from './component/tipcom.vue'
import _ from 'lodash'
import prompt from './component/prompt.vue'
import info from '@/assets/images/home/info.png'
import diamond from '@/assets/images/home/diamond.svg'
import close from '@/assets/images/home/close.png'
import { useRoute, onBeforeRouteLeave, useRouter } from 'vue-router'
// 计算价格加减
import { getPrice } from './price'
// 精度库
import { Decimal } from 'decimal.js'
import { useQuery, useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { STOCKQUOTE, COMMON } from '@/define'
import {
  transformQuoteField,
  transformSocketFieldUsAfterOrBefore
} from '@/utils/formatStock'
import { numFormat, numFormatK } from '@/utils/num_format'
import webSocket from '@/mqtt'
import storage, { StorageType } from '@/utils/storage'
import { useStore } from '@/store'
import * as quotType from '@/store/modules/quot/mutations-type'
import ok1 from '@/assets/images/icons/checked.svg'
import ok2 from '@/assets/images/icons/unchecked.svg'
import { forNumber, forZcNumber } from '../bulkTrading/hooks/bulk'
// 本地indexedb查询
// import { getVal, judgeVal } from '@/utils/indexedb'
import resA from '../../../../public/localClock/testA.json'
import resHK from '../../../../public/localClock/testHK.json'
import resUS from '../../../../public/localClock/testUS.json'
import * as crypoto from '@/utils/crypto'
import { TagsActionTypes } from '@/store/modules/tagsview/action-types'
import { getStkStep, Direction } from '@/utils/stockPrice'
import { ENTRUSTPROPMAP } from '@/constant'
import useTimeZone from './hooks/useTimeZone'
import useUpdateOrder from './hooks/useUpdateOrder'
import useupdateAyersOrder from './hooks/useUpdateAyersOrder'

type TradeType = {
  entrustBs: string
  stockName: string
  stockCode: string
  price: string
  entrustAmount: string
  exchangeType: string
  entrustProp: string
  extendedTrading: boolean
}
export default defineComponent({
  name: 'BulkTrading',
  components: {
    dropdown,
    dropdownInput,
    prompt,
    confirmPopup,
    timeoutDialog,
    tipcom
  },
  setup() {
    const store: any = useStore()
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      tHeaderList: tHeaderList,
      tableData: [],
      tableLoading: false,
      associateList: [] as any,
      currentItem: { label: '限价单 (增强) ', value: 'ELO', valueOf: 'ELO1' },
      typeFlag: false,
      inputFlag: false,
      // 输入框选择的数据
      obj: {
        code: '',
        icon: '',
        label: '',
        value: ''
      },
      num: '', // 数量
      fundAccount: storage.rcGetItem(StorageType.local, 'userInfo'),
      price: '' as any, // 价格
      bigBuyPrice: '', // 计算最大可买价格
      stock: '', // 输入框输入的数据
      dialogVisible: false, // 碎股确认
      confirmPopupFlag: false, // 买入卖出确认
      timeoutDialogFlag: false, // 超时弹出框
      updateFlag: false,
      tradingData: [] as any, // 买入卖出勾选的数据
      tradingObj: { entrustProp: '' } as TradeType,
      unlockFlag: !storage.rcGetItem(StorageType.session, 'unlock'), // 解锁交易
      diamond: diamond, // 图片
      close: close, // 图片
      buyLoading: false, // 卖出loading
      updateLoading: false,
      allocationIndex: '1', // 分配方式
      buyHand: 0, // 当前输入股票 买一手的数量 (美股一手都可以买 可以设置为 1 既不会出现碎股)
      promptTitle: '资产分配', // 碎股显示标题
      buyTitle: '买入确认', // 批量买入确认显示标题
      quotDetail: {
        price: ''
      } as any, // 行情详细
      s5List: S5LIST, // 卖出5挡
      b5List: B5LIST, // 买入5挡
      preOnPrice: '', // 盘前盘后对比价格
      currentDisabled: false, // 类型选项禁用
      sellDirection: '', // 类型选项禁用
      orderid: route.query.orderid,
      preMarketFlag: true, // 选择盘前盘后
      list: listHK, // 选项选中类型
      homeData: [] as any, // 首页传入的值
      marketFlag: true, // 市价单隐藏
      changeFlagNum: 5, // 涨跌幅 显示的颜色 icon
      inputLenovoList: [] as any, // 输入框输入股票名称或者代码 表格自动联想
      HKList: [] as any, // 港币用户列表
      USList: [] as any, // 美元用户列表
      CnyList: [] as any, // A股用户列表
      fundList: [],
      askBidData: {
        ask1: '',
        ask2: '',
        ask3: '',
        ask4: '',
        ask5: '',
        askQty1: '',
        askQty2: '',
        askQty3: '',
        askQty4: '',
        askQty5: '',
        bid1: '',
        bid2: '',
        bid3: '',
        bid4: '',
        bid5: '',
        bidQty1: '',
        bidQty2: '',
        bidQty3: '',
        bidQty4: '',
        bidQty5: ''
      },
      mktCode: {} as { code: string, mkt: string },
      preClose: '',
      changeItemIndex: 0,
      // indexedb 版本信息
      usv: resUS.result.lastVersion,
      hkv: resHK.result.lastVersion,
      av: resA.result.lastVersion,
      ePending: '',
      entrustPrice: '' as any, // 改单价
      exchangeType: 'HK', // 动态右侧显示对应的市场
      isZht: true as any, // 中华通
      height: window.innerHeight - 400 < 467 ? 467 : window.innerHeight - 400,
      taskid: '',
      updateData: [],
      isRecalculate: true
    })
    // 冬令时夏令时
    const { refetchTime, timeIsDst } = useTimeZone()
    const changePreMkt = (val: boolean) => {
      refetchTime()
      if (timeIsDst.value) {
        // 夏令时
        const timer = new Date()
        const start = timer.setHours(4, 0, 0, 0)
        const end = timer.setHours(8, 0, 0, 0)
        const nowTime = new Date().getTime()
        if (nowTime >= start && nowTime <= end) {
          ElMessage.warning('04:00-08:00时段暂不支持选择')
          return
        }
      } else {
        // 冬令时
        const timer = new Date()
        const start = timer.setHours(5, 0, 0, 0)
        const end = timer.setHours(9, 0, 0, 0)
        const nowTime = new Date().getTime()
        if (nowTime >= start && nowTime <= end) {
          ElMessage.warning('05:00-09:00时段暂不支持选择')
          return
        }
      }
      state.preMarketFlag = val
    }
    const moneyType = computed(() => {
      if (!state.obj?.icon) {
        return 'HKD'
      } else {
        switch (state?.obj?.icon) {
          case 'HK':
            return 'HKD'
          case 'US':
            return 'USD'
          default:
            return 'CNY'
        }
      }
    })
    const exchangeRate = computed(
      () =>
        store.getters.getCommonInfo.exchangeRate.filter(
          (item: { ccy: string }) => item.ccy === moneyType.value
        )[0].rate
    )
    let readList: any = route.params.bidAskList || '[]'
    if (route.query.type) {
      readList = crypoto.base64Decrypt(route.query.bidAskList as string) || ''
    }
    const homeDataList = JSON.parse(readList) || []
    state.homeData = new Map()
    homeDataList.forEach((v: any) => {
      state.homeData.set(v.fundAccount, v)
    })
    console.log(route, homeDataList, 'routerQuery homeDataList')
    // 改单
    const { updateOrderFunc } = useUpdateOrder(state)
    const updateOrder = () => {
      const orderinfo = state.tradingData.map((item: any) => {
        return {
          fundAccount: item.fundAccount,
          orderTxnReference: item.externalOrderNo,
          newEntrustAmount: Number(item.num)
        }
      })
      updateOrderFunc({
        params: {
          orderid: Number(state.orderid),
          orderinfo,
          newEntrustPrice: parseFloat(state.price)
        }
      })
    }
    // 柜台订单改单
    const { updateAyersOrder } = useupdateAyersOrder(state)
    // 改单初始化数据
    const initOrderData = () => {
      const type = JSON.parse(route.query?.type as any) || ''
      state.allocationIndex = '1'
      route.meta.title = '改单'
      state.currentDisabled = true
      state.isRecalculate = false
      state.sellDirection =
        type.entrustBs === 'bid' || type.entrustBs === '1' ? '买入' : '卖出'
      state.buyTitle = `确认改单${state.sellDirection}`
      state.currentItem.label = ENTRUSTPROPMAP[type.entrustProp]
      state.currentItem.value = type.entrustProp
      state.price = type.entrustPrice
      state.preMarketFlag = type.extendedTrading
      if (type.entrustProp === 'MO' || type.entrustProp === 'AMO') {
        state.marketFlag = false
        state.price = ''
      } else {
        state.marketFlag = true
      }
      state.num = homeDataList.reduce((pre: any, cur: any) => {
        return new Decimal(pre).plus(Number(cur.entrustAmount)).toNumber()
      }, 0)
      state.tableData.forEach((v: any) => {
        const target = homeDataList.find(
          (item: any) => item.fundAccount === v.fundAccount
        )
        if (target) {
          v.externalOrderNo = target?.externalOrderNo
          v.num = Number(target?.entrustAmount)
        }
      })
      state.tableLoading = false
    }
    // 改单后跳转
    const confirm = () => {
      store.dispatch(TagsActionTypes.ACTION_DEL_VIEW, route)
      if (route.query?.ayers) {
        router.push({
          name: 'otc'
        })
      } else if (state.tradingData.length === 1) {
        router.push({
          name: 'CheckDetail',
          query: {
            orderid: state.orderid
          }
        })
      } else {
        router.push({
          name: 'RealTimeData'
        })
      }
    }
    // 自动聚焦
    const inputNumRef = ref(null)
    const refPrice = ref(null)
    const keydown = (e: any) => {
      if (e.keyCode === 13) {
        nextTick(() => {
          ;(inputNumRef.value as any).focus()
        })
      }
    }
    const subStatus = ref(true)
    const assetInfoParams = ref({})
    const quotHkParams = ref({})
    const quotUsParams = ref({})
    const quotAParams = ref({})
    const assetInfoOptions = ref({
      enabled: false,
      clientId: 'quote',
      notifyOnNetworkStatusChange: true
    })
    const {
      loading: inputLoading,
      refetch: queryStockRefetch,
      onResult: queryStockResult
    } = useQuery(gql.queryStkAssetInfo, assetInfoParams, assetInfoOptions)
    // 请求港股行情
    const quotHkOptions = ref({
      enabled: false,
      clientId: 'quote',
      notifyOnNetworkStatusChange: true
    })
    const { refetch: refetchQuotHk, onResult: onResultHk } = useQuery(
      gql.quotHk,
      quotHkParams,
      quotHkOptions
    )
    // 请求美股行情
    const quotUsOptions = ref({
      enabled: false,
      clientId: 'quote',
      notifyOnNetworkStatusChange: true
    })
    const { refetch: refetchQuotUs, onResult: onResultUs } = useQuery(
      gql.quotUs,
      quotUsParams,
      quotUsOptions
    )
    // 请求A股数据
    const quotAOptions = ref({
      enabled: false,
      clientId: 'quote',
      notifyOnNetworkStatusChange: true
    })
    const { refetch: refetchQuotA, onResult: onResultA } = useQuery(
      gql.quotA,
      quotAParams,
      quotAOptions
    )
    // 获取列表数据
    const { refetch, onResult: onResultTableList } = useQuery(
      gql.fundInfoPositionList,
      null,
      {
        fetchPolicy: 'cache-and-network'
      }
    )
    state.tableLoading = true
    const selectStatusFun = (fundAccountNum: any) => {
      if (state.homeData.has(fundAccountNum)) {
        return true
      } else {
        return false
      }
    }
    // 显示名称加备注
    const displayName = (mapUser: any, fundAccount: any) => {
      if (mapUser.has(fundAccount)) {
        const name =
          mapUser.get(fundAccount).nameCn ||
          mapUser.get(fundAccount).englishFirstname +
            mapUser.get(fundAccount).englishLastname
        if (mapUser.get(fundAccount)?.remark) {
          return name + '(' + mapUser.get(fundAccount).remark + ')'
        } else {
          return name
        }
      } else {
        return '- -'
      }
    }
    watch(
      () => state.list,
      (newValue: any) => {
        console.log(newValue)
        state.changeItemIndex = 0
      },
      { deep: true }
    )
    // 计算总资产
    const computedAsset = (clientId: string, data: any) => {
      const arr = data.filter((v: any) => v.clientid === clientId)
      if (arr.length > 0) {
        if (moneyType.value === 'HKD') {
          return numFormat(arr[0].asset, 2)
        } else {
          return new Decimal(arr[0].asset)
            .div(exchangeRate.value)
            .toNumber()
            .toLocaleString()
        }
      }
    }
    // 根据市场获取列表
    const fundListByMkt = (moneyType: string) => {
      let arr = state.HKList
      if (moneyType === 'HKD') {
        arr = state.HKList
      } else if (moneyType === 'USD') {
        arr = state.USList
      } else if (moneyType === 'CNY') {
        arr = state.CnyList
      }
      return _.cloneDeep(arr)
    }
    // 获取右侧列表数据
    onResultTableList(res => {
      if (res.data) {
        const { fundInfoList, positionList, lpoaManagersBindInfoList } =
          res.data
        const mapUser = new Map()
        if (lpoaManagersBindInfoList.data) {
          // eslint-disable-next-line
          lpoaManagersBindInfoList.data[0]?.bindInfo.forEach((v: any) => {
            mapUser.set(v.account, v)
          })
        }
        const arr = [] as any
        fundInfoList.data.find((item: any) => {
          arr.push(...item.info)
        })
        // 港币(资金明细)
        state.HKList = arr.filter((v: any) => v.exchangeType === 'HK')
        // 美元(资金明细 --- vuex == US 时为这个)
        state.USList = arr.filter((v: any) => v.exchangeType === 'US')
        state.CnyList = arr.filter((v: any) => v.exchangeType === 'A')
        // 定义渲染表格数组 (包装数据)
        // eslint-disable-next-line
        const funList = fundListByMkt(moneyType.value)
        state.fundList = fundInfoList.data
        funList.forEach((v: any) => {
          v.stockName = '--'
          v.currentAmount = '--'
          v.enableAmountComputed = 0
          v.num = 0
          v.nameCn = displayName(mapUser, v.fundAccount)
          v.selectStatus = selectStatusFun(v.fundAccount)
          v.assetDisplayNF = computedAsset(v.fundAccount, fundInfoList.data)
          v.enableBalanceDisplayN = numFormat(v.enableBalanceDisplay, 2)
        })
        state.tableData = funList
        const totalArr = [] as any
        // 处理持仓明细的数据
        console.log(positionList, '持仓明细 -- > 后面进行精准匹配')
        state.inputLenovoList = new Map()
        positionList.data.find((item: any) => {
          totalArr.push(...item.info.rows)
          item.info.rows.forEach((v: any) => {
            state.inputLenovoList.set(v.clientId, item.info.rows)
          })
        })
        if (route.query?.type) {
          initOrderData()
          return
        }
        // 买入卖出显示股数
        if (homeDataList[0]?.stockCode) {
          state.tableData.forEach((v: any) => {
            if (state.inputLenovoList.has(v.fundAccount)) {
              state.inputLenovoList.get(v.fundAccount).forEach((s: any) => {
                if (s.stockCode === state.obj.code) {
                  v.stockName = s.stockNameGb
                  v.currentAmount = s.currentAmount + '股'
                  v.enableAmountComputed = s.enableAmount
                  // v.num = homeDataList[0].entrustAmount
                }
              })
            }
            // 左侧数量显示
            const target = homeDataList.find(
              (item: any) => item.fundAccount === v.fundAccount
            )
            if (target) {
              v.externalOrderNo = target?.externalOrderNo
              v.num = Number(target?.entrustAmount) || 0
              v.price = Number(target?.entrustPrice) || ''
            }
          })
          // 重新下单/改单 给数量赋值
          nextTick(() => {
            state.num = homeDataList[0].entrustAmount
            state.price = homeDataList[0].entrustPrice
          })
        }
        state.tableLoading = false
      }
    })
    // 最大可买
    const maxBuy = computed(() => {
      const selectedArr = state.tableData.filter(
        (item: { selectStatus: boolean }) => item.selectStatus
      )
      const arr = selectedArr?.map((v: any) => v.enableBalanceDisplay)
      const enableList = arr.map(
        item =>
          parseFloat(item) / Number(state.bigBuyPrice) -
          ((parseFloat(item) / Number(state.bigBuyPrice)) % state.buyHand)
      )
      const bayHandSome = enableList?.reduce(
        (pre: any, cur: any) => Number(pre) + Number(cur),
        0
      )
      // console.log(arr, enableList, '最大可买')
      if (!state.bigBuyPrice) {
        return '- -'
      }
      return isNaN(bayHandSome) || !selectedArr.length
        ? '- -'
        : bayHandSome.toLocaleString()
    })
    // 最大可卖
    const maxSell = computed(() => {
      const selectedArr = state.tableData.filter(
        (item: { selectStatus: boolean }) => item.selectStatus
      )
      return selectedArr.length && state.obj.label !== ''
        ? numFormat(
            selectedArr
              ?.map((v: any) => v.enableAmountComputed)
              .reduce((a: any, b: any) => Number(a) + Number(b)),
            0
          )
        : '- -'
    })
    // 监听选中的是美股还是港股 / 选中的股票代码
    watch(
      () => state.obj,
      () => {
        const arr = fundListByMkt(moneyType.value)
        state.tableData.forEach((v: any) => {
          arr.forEach((s: any) => {
            if (v.fundAccount === s.fundAccount) {
              v.assetDisplay = s.assetDisplay
              v.enableBalanceDisplay = s.enableBalanceDisplay
              v.assetDisplayNF = computedAsset(v.fundAccount, state.fundList)
              v.enableBalanceDisplayN = numFormat(v.enableBalanceDisplay, 2)
            }
          })
        })
        state.tHeaderList.forEach(item => {
          if (item.prop === 'enableBalanceDisplayN') {
            item.label = `市场购买力(${moneyType.value})`
          }
        })
      },
      { deep: true }
    )
    // 行情实时推送数据
    const mqttQuotDetail = computed(() => store.getters.quot.detail)
    // 港股买卖五档行情推送
    const quotAskBid = computed(() => store.getters.quot.askBid)
    // 计算属性 ( 勾选中的数组 )
    const bulkBuyList: any = computed(() => {
      const arr = state.tableData.filter((v: any) => v.selectStatus)
      const obj = {
        list: arr
      }
      return obj
    })
    // 平均分配计算方式
    const averageComputed = () => {
      // const arr = bulkBuyList.value.list
      // const average = Number(state.num) / (arr.length ? arr.length : 0)
      // state.tableData.forEach((v: any) => {
      //   if (v.selectStatus) {
      //     v.num = ~~average
      //   }
      // })
      // // eslint-disable-next-line
      // computedLine()
      // 新的分配方式
      const arr = forNumber(state.buyHand, state.num, bulkBuyList.value.list)
      console.log(arr, '平均分配')
    }
    // 计算属性 计算涨跌的占比
    const computedRiseFall = computed(() => {
      if (state.quotDetail.changePct) {
        if (state.changeFlagNum === 2) {
          return '+' + numFormat(state.quotDetail.changePct * 100, 2) + '%'
        } else {
          return numFormat(state.quotDetail.changePct * 100, 2) + '%'
        }
      }
    })
    // 计算属性 计算涨跌的颜色
    const computedChangeColor = computed(() => {
      if (state.changeFlagNum === 0) {
        return 'num1-down'
      } else if (state.changeFlagNum === 1) {
        return 'fn-num1'
      } else if (state.changeFlagNum === 2) {
        return 'num1-up'
      } else if (state.changeFlagNum === 5) {
        return 'num1-nor'
      }
    })
    // 资产比例计算方法
    const assetsComputed = () => {
      if (!state.obj.label) {
        return
      }
      const arr = bulkBuyList.value.list
      // 计算占比
      const assets = arr.map((v: any) => v.enableBalanceDisplay)
      const total = assets.length
        ? assets.reduce((a: any, b: any) => Number(a) + Number(b))
        : 0
      const accounted: number[] = []
      assets.forEach((v: any) => {
        // 处理总数为0时分配情况
        if (total === 0) {
          accounted.push(0.3333)
        } else {
          accounted.push(
            Math.floor((Number(v) / total) * 1000000000000) / 1000000000000
          )
        }
      })
      bulkBuyList.value.list.forEach((v: any, i: any) => {
        if (v.selectStatus) {
          // v.num = ~~(accounted[i] * Number(state.num))
          v.accounted = accounted[i]
        }
      })
      if (state.isRecalculate) {
        forZcNumber(
          state.buyHand,
          state.num,
          bulkBuyList.value.list,
          state.exchangeType
        )
      }
      // eslint-disable-next-line
      // computedLine()
    }
    // 计算属性 ( 勾选中的数组数量之和 )
    const tableNum: any = computed(() => {
      if (bulkBuyList.value.list.length < 1) {
        return 0
      }
      return bulkBuyList.value.list
        ?.map((v: any) => (v.num ? v.num : 0))
        .reduce((prev: any, cur: any) => Number(prev) + Number(cur))
    })
    // 计算多余的加到第一个上去/* 不等 */ 改变数值跳到 自定义分配
    const computedLine = () => {
      if (bulkBuyList.value.list.length < 1) {
        return
      }
      const assets = bulkBuyList.value.list.map((v: any) => (v.num ? v.num : 0))
      const total = assets.reduce((a: any, b: any) => Number(a) + Number(b))
      if (Number(state.num) > Number(total)) {
        // 不等的时候把多余的加给第一个
        bulkBuyList.value.list[0].num += Number(state.num) - Number(total)
      }
      nextTick(() => {
        // eslint-disable-next-line
        if (state.num != tableNum.value) {
          state.allocationIndex = '2'
          state.num = tableNum.value
        }
      })
    }
    // 计算属性 计算金额/最大可买
    const amountComputed = computed(() => {
      if (!state.obj.label) return '- -'
      const num = new Decimal(state.price ? state.price : 0)
        .mul(new Decimal(state.num ? state.num : 0))
        .toNumber()
      if (state.obj.icon === 'HK') {
        return num ? numFormat(num, 2) + ' ' + 'HKD' : '' || '- -'
      } else if (state.obj.icon === 'US') {
        return num ? numFormat(num, 2) + ' ' + 'USD' : '' || '- -'
      } else {
        return num ? numFormat(num, 2) + ' ' + 'CNY' : '' || '- -'
      }
    })
    // 监听数量变化
    watch(
      () => state.num,
      (newValue: any) => {
        state.tradingObj = {
          entrustBs: state.buyTitle.slice(0, 2),
          stockName: state.obj.label,
          stockCode: state.obj.code,
          price: state.price,
          entrustAmount: state.num,
          exchangeType: state.obj.icon,
          entrustProp: ENTRUSTPROPMAP[state.currentItem.value],
          extendedTrading: state.preMarketFlag
        }
        console.log('state.num', state.num)
        newValue = (newValue + '').replace(/[^0-9]/g, '')
        newValue = (newValue + '').replace(/0*([1-9]\d*|0\.\d+)/, '$1')
        if (newValue > 0) {
          state.num = newValue
          if (state.allocationIndex === '0') {
            averageComputed()
          }
          if (state.allocationIndex === '1') {
            assetsComputed()
          }
        } else {
          state.num = ''
          averageComputed()
        }
      }
    )
    // 监听价格变化
    watch(
      () => state.price,
      (newValue: any) => {
        state.tradingObj = {
          entrustBs: state.buyTitle.slice(0, 2),
          stockName: state.obj.label,
          stockCode: state.obj.code,
          price: state.price,
          entrustAmount: state.num,
          exchangeType: state.obj.icon,
          entrustProp: ENTRUSTPROPMAP[state.currentItem.value],
          extendedTrading: state.preMarketFlag
        }
        newValue = (newValue + '')
          .replace(/0*([1-9]\d*|0\.\d+)/, '$1')
          .replace(/\D*(\d*)(\.?)(\d{0,4})\d*/, '$1$2$3')
        if (newValue >= 0) {
          state.price = newValue
          if (
            state.currentItem.value === 'MO' ||
            state.currentItem.value === 'AMO'
          ) {
            state.bigBuyPrice = state.price || state.quotDetail.price
          } else {
            state.bigBuyPrice = state.price
          }
        } else {
          state.price = ''
          if (
            state.currentItem.value === 'MO' ||
            state.currentItem.value === 'AMO'
          ) {
            state.bigBuyPrice = state.price || state.quotDetail.price
          } else {
            state.bigBuyPrice = state.price
          }
        }
      }
    )
    // 买卖档位价格颜色
    const autoColor = (price: any) => {
      if (price === '--') {
        return
      }
      if (Number(state.preOnPrice) > Number(price)) {
        return 'span-green'
      } else if (Number(state.preOnPrice) === Number(price)) {
        return 'span-gray'
      } else if (Number(state.preOnPrice) < Number(price)) {
        return 'span-red'
      }
    }
    // 监听输入获取的数据
    watch(
      () => state.quotDetail,
      (newValue: any) => {
        console.log(newValue, '输入股票查询数据1111111111111111111111111')
        if (state.obj.label === '') {
          return
        }
        // 中华通赋值
        if (newValue.isZht !== undefined) {
          state.isZht = newValue.isZht
        }
        state.exchangeType = state.quotDetail?.assetId?.split('.')[1]
        state.preClose = state.quotDetail.preClose
        if (
          state.currentItem.value === 'MO' ||
          state.currentItem.value === 'AMO'
        ) {
          state.bigBuyPrice = newValue.price
        }
        state.buyHand = Number(newValue.lotSize) || 100
        if (newValue.change?.includes('-')) {
          // 跌 0
          state.changeFlagNum = 0
        } else if (Number(newValue.change) === 0) {
          // 不涨不跌 1
          state.changeFlagNum = 1
        } else if (newValue.change) {
          // 涨 2
          state.changeFlagNum = 2
        } else {
          state.changeFlagNum = 1
        }
      },
      { deep: true }
    )
    // 监听选择了那个分配方式
    watch(
      () => state.tableData,
      (newValue: any) => {
        console.log(newValue, 'tableData')
        // 改变下单顺序触发重新分配 => 必须存在label的情况下
        if (state.allocationIndex === '2') {
          state.num = tableNum.value
        } else if (state.allocationIndex === '0') {
          // 平均分配如果 出现小数点 则多余的数量加到最上面一个去 ( 总数量 !== 填写数量之和 )
          // computedLine()
        } else {
          // computedLine()
        }
      },
      { deep: true }
    )
    const change = (data: { btnType: String, data: any }) => {
      switch (data.btnType) {
        case 'add':
          if (state.buyHand) {
            state.allocationIndex = '2'
            data.data.num = Number(data.data.num) + state.buyHand
          }
          break
        case 'sub':
          if (state.buyHand) {
            state.allocationIndex = '2'
            if (Number(data.data.num) - state.buyHand < 0) {
              return
            }
            data.data.num = Number(data.data.num) - state.buyHand
          }
          break
        case 'inputBlur':
          console.log('输入框输入事件')
          state.allocationIndex = '2'
          console.log(data)
          break
        case 'btnTypeC':
          // eslint-disable-next-line
          brokenStock()
          break
        default:
          break
      }
    }
    const handlerClick = (val: String) => {
      switch (val) {
        case 'subPrice':
          console.log('subPrice')
          if (!state.price) {
            return
          }
          const price1 = new Decimal(state.price).sub(
            new Decimal(
              getStkStep(
                state.price,
                state.obj.icon,
                state.quotDetail.schType,
                Direction.Sub
              )
            )
          ) as any
          state.price = numFormat(price1, 3).replace(/,/g, '')
          break
        case 'addPrice':
          console.log('addPrice')
          if (!state.price) {
            return
          }
          // numFormat(price, 3).replace(/,/g, '')
          const price = new Decimal(state.price).plus(
            new Decimal(
              getStkStep(
                state.price,
                state.obj.icon,
                state.quotDetail.schType,
                Direction.Add
              )
            )
          ) as any
          state.price = numFormat(price, 3).replace(/,/g, '')
          break
        case 'addNum':
          if (state.allocationIndex === '2') {
            return
          }
          state.num = new Decimal(Number(state.num)).plus(
            new Decimal(state.buyHand)
          ) as any
          state.isRecalculate = true
          break
        case 'subNum':
          if (state.allocationIndex === '2') {
            return
          }
          if (!state.num || Number(state.num) - state.buyHand < 0) {
            return
          }
          state.num = new Decimal(Number(state.num)).sub(
            new Decimal(state.buyHand)
          ) as any
          state.isRecalculate = true
          break
        default:
          break
      }
    }
    // 全选
    const checkAll = (val: Boolean) => {
      if (val) {
        if (state.allocationIndex === '0') {
          averageComputed()
        }
        if (state.allocationIndex === '1') {
          assetsComputed()
        }
      }
    }
    // 表格选中
    const changeSelect = () => {
      if (state.allocationIndex === '0') {
        averageComputed()
      }
      if (state.allocationIndex === '1') {
        assetsComputed()
      }
    }
    // 选择了选项(下拉)
    const changeItem = (
      value: any,
      label: string,
      valueOf: string,
      index: any
    ) => {
      state.tradingObj.entrustProp = ENTRUSTPROPMAP[value]
      // 选中了市价单 竞价市价单 隐藏价格 金额显示 - - 最大可买 - -
      if (value === 'MO' || value === 'AMO') {
        state.marketFlag = false
        state.price = ''
      } else {
        state.marketFlag = true
        // eslint-disable-next-line
        state.price = state.quotDetail.price
      }
      state.currentItem.value = value
      state.currentItem.label = label
      state.currentItem.valueOf = valueOf
      state.typeFlag = false
      state.changeItemIndex = index
    }
    // 查询对应个股行情
    const queryQuot = (data: any) => {
      const { id, mkt, code } = data
      state.mktCode = {
        mkt,
        code
      }
      if (mkt === 'HK') {
        quotHkParams.value = {
          filter: {
            assetIds: [id],
            fields: STOCKQUOTE.detailHk
          }
        }
        if (!quotHkOptions.value.enabled) {
          quotHkOptions.value.enabled = true
        } else {
          refetchQuotHk(quotHkParams.value)
        }
      } else if (mkt === 'US') {
        quotUsParams.value = {
          filter: {
            assetIds: [id],
            fields: STOCKQUOTE.detailUs
          }
        }
        if (!quotUsOptions.value.enabled) {
          quotUsOptions.value.enabled = true
        }
        refetchQuotUs(quotUsParams.value)
      } else if (mkt === 'SZ' || mkt === 'SH') {
        quotAParams.value = {
          filter: {
            assetIds: [id],
            fields: STOCKQUOTE.detailA
          }
        }
        if (!quotAOptions.value.enabled) {
          quotAOptions.value.enabled = true
        }
        refetchQuotA(quotAParams.value)
      }
    }
    const init = () => {
      if (homeDataList.length) {
        const { stockCode, exchangeType, stockNameGb, entrustProp } =
          homeDataList[0]
        // 改变选择( 限价单(增强) )类型的数据
        if (exchangeType === 'HK') {
          state.list = listHK
        } else {
          state.list = listUS
          state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        }
        if (entrustProp) {
          state.currentItem = JSON.parse(JSON.stringify(state.list)).filter(
            (v: any) => v.value === entrustProp
          )[0]
        }
        // 柜台订单
        if (!state.currentItem) {
          state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        }
        // 市价单隐藏价格
        if (
          state.currentItem.value === 'MO' ||
          state.currentItem.value === 'AMO'
        ) {
          state.marketFlag = false
          state.price = ''
        } else {
          state.marketFlag = true
          // eslint-disable-next-line
          state.price = state.quotDetail.price
        }
        const code = `${stockCode}.${exchangeType}`
        if (stockCode && exchangeType) {
          const params = {
            id: code,
            mkt: exchangeType,
            code: stockCode
          }
          state.obj = {
            code: stockCode,
            icon: exchangeType,
            label: stockNameGb,
            value: ''
          }
          state.stock = code
          queryQuot(params)
        }
      }
    }
    // 港股个股行情
    const getQuotCallback = (list: any) => {
      if (state.obj.label === '' || !state.obj.label) {
        return
      }
      subStatus.value = true
      let data
      const { topic2, topic3, topic21, topic2ByML, topic3ByML } = getTopic(state)
      if (state.mktCode.mkt === 'HK') {
        data = transformQuoteField(list, STOCKQUOTE.detailHk) || []
        state.quotDetail = data.length ? data[0] : {}
      } else if (state.mktCode.mkt === 'US') {
        // 需要盘前盘后数据
        data = transformQuoteField(list, STOCKQUOTE.detailUs) || []
        const res = data.length ? data[0] : {}
        switch (res.afBfStatus) {
          case '0':
            // 取162行情数据
            let beforeData = {
              high: '--',
              low: '--',
              price: '',
              change: '',
              changePct: '',
              bid1: '',
              bidQty1: '',
              ask1: '',
              askQty1: ''
            }
            if (res.fieldUsAfter !== '') {
              beforeData = transformSocketFieldUsAfterOrBefore(
                res.fieldUsBefore
              )
            }
            state.quotDetail = {
              ...res,
              ...beforeData
            }
            break
          case '1':
            // 取163行情数据
            let afterData = {
              high: '--',
              low: '--',
              price: '',
              change: '',
              changePct: '',
              bid1: '',
              bidQty1: '',
              ask1: '',
              askQty1: ''
            }
            if (res.fieldUsAfter !== '') {
              afterData = transformSocketFieldUsAfterOrBefore(res.fieldUsAfter)
            }
            state.quotDetail = {
              ...res,
              ...afterData
            }
            break
          default:
            state.quotDetail = data.length ? data[0] : {}
            state.preOnPrice = state.quotDetail.preClose
            break
        }
      } else if (state.mktCode.mkt === 'SH' || state.mktCode.mkt === 'SZ') {
        data = transformQuoteField(list, STOCKQUOTE.detailA) || []
        state.quotDetail = data.length ? data[0] : {}
      }
      console.log('data.lengthdata.length', data)
      let b5List = state.b5List
      let s5List = state.s5List
      if (state.mktCode.mkt === 'HK') {
        webSocket.subscribe([topic2, topic3])
      } else if (state.mktCode.mkt === 'US') {
        webSocket.subscribe([topic2, topic21])
        b5List = state.b5List.filter(val => val.qtyKey === 'bidQty1')
        s5List = state.s5List.filter(val => val.qtyKey === 'askQty1')
      } else if (state.mktCode.mkt === 'SH' || state.mktCode.mkt === 'SZ') {
        webSocket.subscribe([topic2ByML, topic3ByML])
        b5List = state.b5List.filter(val => val.qtyKey === 'bidQty1')
        s5List = state.s5List.filter(val => val.qtyKey === 'askQty1')
      }
      // 买卖档位数据
      state.b5List = b5List.map(val => {
        return {
          ...val,
          price: state.quotDetail[val.priceKey] || COMMON.EMPTY_LABEL,
          qty: state.quotDetail[val.qtyKey] || COMMON.EMPTY_LABEL
        }
      })
      state.s5List = s5List.map(val => {
        return {
          ...val,
          price: state.quotDetail[val.priceKey] || COMMON.EMPTY_LABEL,
          qty: state.quotDetail[val.qtyKey] || COMMON.EMPTY_LABEL
        }
      })
      // ---------------- 输入股票代码输入框显示价格 ------------------------
      if (state.marketFlag) {
        if (!state.entrustPrice) {
          state.price = state.quotDetail.price
        } else {
          state.price = numFormat(state.entrustPrice, 3).replace(/,/g, '')
        }
      }

      if (state.mktCode.mkt === 'US' && showExtendedQuotationText.value) {
        state.b5List = [B5LIST[0]]
        state.s5List = [S5LIST[0]]
      }
    }
    // 港股行情数据
    onResultHk(result => {
      const { data, loading } = result
      if (!loading) {
        const res = data.quotHk.result?.data || []
        state.preOnPrice = res.length && res[0][6]
        getQuotCallback(res)
      }
    })
    // 美股行情数据
    onResultUs(result => {
      const { data, loading } = result
      if (!loading) {
        const res = data.quotUs.result?.data || []
        state.preOnPrice = res.length && res[0][2]
        getQuotCallback(res)
      }
    })
    // A股行情数据
    onResultA(result => {
      const { data, loading } = result
      if (!loading) {
        const res = data.quotA.result?.data || []
        // 盘前盘后对比价格
        state.preOnPrice = res.length && res[0][6]
        getQuotCallback(res)
      }
    })
    watch([mqttQuotDetail, subStatus], ([quotVal, statusVal]) => {
      if (statusVal && quotVal && quotVal.length) {
        const result = quotVal[0]
        state.quotDetail = {
          ...state.quotDetail,
          price: result.price,
          change: result.change,
          changePct: result.changePct
        }
      }
    })
    watch([quotAskBid, subStatus], ([quotVal, statusVal]) => {
      console.log('quotVal', quotVal)
      if (statusVal && quotVal && quotVal.length) {
        const list = quotVal[0]
        // 买卖档位数据
        state.b5List = state.b5List.map(val => {
          return {
            ...val,
            price: list[val.priceKey],
            qty: list[val.qtyKey]
          }
        })
        state.s5List = state.s5List.map(val => {
          return {
            ...val,
            price: list[val.priceKey],
            qty: list[val.qtyKey]
          }
        })
      }
    })
    // 选择了选项(输入框)
    const selectItem = (obj: any) => {
      const arrHK = JSON.parse(JSON.stringify(listHK)).map(
        (v: any) => v.valueOf
      )
      const arrUS = JSON.parse(JSON.stringify(listUS)).map(
        (v: any) => v.valueOf
      )
      const arrA = JSON.parse(JSON.stringify(listA)).map((v: any) => v.valueOf)
      const currentItemFlag = state.list.findIndex(
        (v: any) => !arrHK.includes(v.valueOf)
      )
      const currentItemUSFlag = state.list.findIndex(
        (v: any) => !arrUS.includes(v.valueOf)
      )
      const currentItemAFlag = state.list.findIndex(
        (v: any) => !arrA.includes(v.valueOf)
      )
      const catchCurrentItem = JSON.parse(JSON.stringify(state.currentItem))
      // 更改选项数组
      if (obj?.icon === 'HK' && currentItemFlag === -1) {
        state.list = listHK
        state.currentItem = catchCurrentItem
      } else if (obj.icon === 'HK' && currentItemFlag !== -1) {
        state.list = listHK
        state.currentItem = {
          label: '限价单 (增强) ',
          value: 'ELO',
          valueOf: 'ELO1'
        }
        state.marketFlag = true
      } else if (obj.icon === 'US' && currentItemUSFlag === -1) {
        state.list = listUS
        state.currentItem = catchCurrentItem
      } else if (obj.icon === 'US' && currentItemUSFlag !== -1) {
        state.list = listUS
        state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        state.marketFlag = true
      } else if (
        (obj.icon === 'SZ' || obj.icon === 'SH') &&
        currentItemAFlag !== -1
      ) {
        state.list = listA
        state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO3' }
        state.marketFlag = true
      }
      queryQuot(obj)
      state.obj = obj
      state.inputFlag = false
      state.num = ''
      // 选中的股票代码
      state.tableData.forEach((v: any) => {
        if (state.inputLenovoList.has(v.fundAccount)) {
          state.inputLenovoList.get(v.fundAccount).forEach((s: any) => {
            if (s.stockCode === state.obj.code) {
              v.stockName = s.stockNameGb
              v.currentAmount = s.currentAmount + '股'
              v.enableAmountComputed = s.enableAmount
            }
          })
        }
      })
      // 聚焦数量输入框
      if (state.marketFlag) {
        nextTick(() => {
          ;(refPrice.value as any).focus()
        })
      } else {
        nextTick(() => {
          ;(inputNumRef.value as any).focus()
        })
      }
    }
    // 更新码表
    // const stkParams = ref({})
    // const stkOptions = ref({
    //   enabled: false,
    //   clientId: 'quote',
    //   notifyOnNetworkStatusChange: true
    // })
    // const { refetch: refetchFunStk, onResult: onResultStk } = useQuery(
    //   gql.stkUpdateStockALL,
    //   stkParams,
    //   stkOptions
    // )
    // onResultStk(res => {
    //   if (res.data) {
    //     const banStr = storage.rcGetItem(StorageType.local, 'version')
    //     const banArr = banStr.split(',')
    //     const versionLoc = Number(banArr[3])
    //     const { stkA, stkHk, stkUs } = res.data
    //     const lastVersion =
    //       stkA.result.lastVersion +
    //       stkHk.result.lastVersion +
    //       stkUs.result.lastVersion
    //     console.log(versionLoc, lastVersion, '对比')
    //     const addedList = stkUs.result.added
    //       .concat(stkHk.result.added)
    //       .concat(stkA.result.added)
    //     const exList = stkUs.result.expired
    //       .concat(stkHk.result.expired)
    //       .concat(stkA.result.expired)
    //     const updateList = stkUs.result.updated
    //       .concat(stkHk.result.updated)
    //       .concat(stkA.result.updated)
    //     const arr = addedList.concat(exList).concat(updateList)
    //     console.log(arr, 'arrr')
    //     // 循环请求数据
    //     let index = 1
    //     // 判断已经没有数据
    //     if (versionLoc !== lastVersion) {
    //       console.log('更新', index)
    //       ++index
    //       stkParams.value = {
    //         filterHk: {
    //           currentPage: 1,
    //           pageSize: 5000,
    //           version: res.data.stkHk.result.lastVersion,
    //           market: 'HK'
    //         },
    //         filterUs: {
    //           currentPage: 1,
    //           pageSize: 5000,
    //           version: res.data.stkUs.result.lastVersion,
    //           market: 'US'
    //         },
    //         filterA: {
    //           currentPage: 1,
    //           pageSize: 5000,
    //           version: res.data.stkA.result.lastVersion,
    //           market: 'ML'
    //         }
    //       }
    //       stkOptions.value = {
    //         enabled: true,
    //         clientId: 'quote',
    //         notifyOnNetworkStatusChange: true
    //       }
    //       //  refetchFunStk()
    //     } else {
    //       console.log('相同了')
    //     }
    //     const numStr =
    //       stkUs.result.lastVersion +
    //       ',' +
    //       stkHk.result.lastVersion +
    //       ',' +
    //       stkA.result.lastVersion
    //     const num =
    //       stkUs.result.lastVersion +
    //       stkHk.result.lastVersion +
    //       stkA.result.lastVersion
    //     storage.rcSetItem(
    //       StorageType.local,
    //       'version',
    //       numStr + ',' + num + ',' + '1'
    //     )
    //     // 增量更新码表
    //     console.time()
    //     judgeVal(
    //       'web_trade',
    //       numStr,
    //       num,
    //       stkUs.result,
    //       stkHk.result,
    //       stkA.result,
    //       true
    //     )
    //     console.timeEnd()
    //   }
    // })
    // 查询股票返回值
    queryStockResult(result => {
      const { data, loading } = result
      if (!loading) {
        let array = [...data?.stkAssetInfo.result.data]
        if (process.env.VUE_APP_H5_A_SWITCH === 'off') {
          array = array.filter((v: any) => v.mkt === 'US' || v.mkt === 'HK')
        }
        // if (array.length > 0 && state.ePending !== 'pending') {
        //   stkOptions.value = {
        //     enabled: true,
        //     clientId: 'quote',
        //     notifyOnNetworkStatusChange: true
        //   }
        //   refetchFunStk()
        // }
        state.associateList = array.map((res: any) => ({
          id: res.id,
          label: res.sn,
          value: 1,
          icon: res.mkt,
          code: res.c,
          ...res
        }))
      }
    })
    // 输入框输入的事件 (接入防抖)
    const inputStock = _.debounce(() => {
      if (state.stock === '') {
        state.associateList = []
        return
      }
      // 搜索本地码表
      // console.time()
      // getVal('web_trade', 'db01', '0201', state.stock, (e: any) => {
      //   console.log(e, '本地码表数据')
      //   state.ePending = e
      //   if (e === 'error') {
      //     console.log('进来了')
      //     // 码表被删除了 重新存储本地码表
      //     judgeVal(
      //       'web_trade',
      //       state.usv + ',' + state.hkv + ',' + state.av,
      //       state.usv + state.av + state.hkv,
      //       resUS,
      //       resHK,
      //       resA,
      //       false
      //     )
      //     return
      //   }
      //   if (e.length < 1 || e === 'pending') {
      //     state.associateList = []
      //     assetInfoParams.value = {
      //       filter: {
      //         searchContent: state.stock
      //       }
      //     }
      //     // 获取版本号
      //     const banStr = storage.rcGetItem(StorageType.local, 'version')
      //     const banArr = banStr.split(',')
      //     stkParams.value = {
      //       filterHk: {
      //         currentPage: 1,
      //         pageSize: 5000,
      //         version: Number(banArr[1]),
      //         market: 'HK'
      //       },
      //       filterUs: {
      //         currentPage: 1,
      //         pageSize: 5000,
      //         version: Number(banArr[0]),
      //         market: 'US'
      //       },
      //       filterA: {
      //         currentPage: 1,
      //         pageSize: 5000,
      //         version: Number(banArr[2]),
      //         market: 'ML'
      //       }
      //     }
      //     if (assetInfoOptions.value.enabled) {
      //       queryStockRefetch(assetInfoParams.value)
      //     } else {
      //       assetInfoOptions.value.enabled = true
      //     }
      //     return
      //   }
      //   state.associateList = e.map((res: any) => ({
      //     id: res.id,
      //     label: res.sn,
      //     value: 1,
      //     icon: res.mkt,
      //     code: res.c,
      //     ...res
      //   }))
      // })
      // console.timeEnd()
      // 查询码表 搜索股票
      // queryStock()
      // queryStockRefetch(assetInfoParams.value)
      assetInfoParams.value = {
        filter: {
          searchContent: state.stock,
          stkTypeList: ['4']
        }
      }
      assetInfoOptions.value.enabled = true
      state.inputFlag = true
    }, 500)
    // 清除输入框输入的数据
    const clearInput = () => {
      state.preMarketFlag = true
      state.preOnPrice = ''
      state.isZht = true
      state.preClose = ''
      state.stock = ''
      state.obj = {} as any
      state.inputFlag = false
      state.s5List = S5LIST
      state.b5List = B5LIST
      state.quotDetail = {} as any
      state.price = ''
      state.num = ''
      // 清除右边数组 显示的股数 股票名称
      state.tableData.forEach((v: any) => {
        v.stockName = '- -'
        v.currentAmount = 0
        v.enableAmountComputed = 0
      })

     const { topic2, topic3, topic21 } = getTopic(state)

      store.commit(quotType.QUOT_DETAIL, { detail: [] })
      store.commit(quotType.ASKBID5, { askBid: [] })
      if (state.mktCode.mkt === 'HK') {
        subStatus.value = false
        webSocket.unsubscribe([topic2, topic3])
      } else if (state.mktCode.mkt === 'US') {
        subStatus.value = false
        webSocket.unsubscribe([topic2, topic21])
      }
    }
    // 购买验证
    const purchaseValidation = (title: string) => {
      state.tradingObj.entrustBs = title.slice(0, 2)
      state.tradingObj.extendedTrading = state.preMarketFlag
      // 批量买入数据(勾选加上选择的顺序)
      if (bulkBuyList.value.list.length < 1) {
        ElMessage({
          message: '请勾选客户账号',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (state.stock === '') {
        ElMessage({
          message: '请输入股票代码',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (
        Number(state.price) === 0 &&
        state.currentItem.value !== 'MO' &&
        state.currentItem.value !== 'AMO'
      ) {
        ElMessage({
          message: '价格不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (Number(state.num) === 0) {
        ElMessage({
          message: '数量不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      // 因为需要兼容A股市场没有行情，默认100股为一手
      // if (state.mktCode.mkt === 'SH' || state.mktCode.mkt === 'SZ') {
      //   state.buyHand = 100
      // }
      // 查看是否有碎股
      if (
        bulkBuyList.value.list.find((v: any) => v.num % state.buyHand !== 0)
      ) {
        // 有碎股
        state.dialogVisible = true
      } else {
        // 买入信息(顺序 bulkBuyList.value.list)
        state.tradingData = bulkBuyList.value.list
        state.buyTitle = title
        // 判断是否为中华通
        if (state.isZht === '0' && title === '买入确认') {
          ElMessageBox.confirm(
            '标有该标识的股票，不属于A股通标的，暂不支持交易。为保障您的权益，您扔可尝试下单，避免在标识状态错误时造成损失。',
            '交易提醒',
            {
              confirmButtonText: '仍要下单',
              cancelButtonText: '知道了',
              customClass: 'message-info-change',
              showClose: false,
              center: true
            }
          )
            .then(() => {
              state.confirmPopupFlag = true
            })
            .catch(() => ({}))
        } else {
          state.confirmPopupFlag = true
        }
      }
    }
    // 批量买入
    const bulkBuy = () => {
      if (
        state.currentItem.value === 'MO' ||
        state.currentItem.value === 'AMO'
      ) {
        state.price = ''
      } else {
        // eslint-disable-next-line
        // state.price = state.quotDetail.price / * 算最大可买 / 影响购买价格 * /
      }
      purchaseValidation('买入确认')
    }
    // 批量卖出
    const batchSell = () => {
      if (
        state.currentItem.value === 'MO' ||
        state.currentItem.value === 'AMO'
      ) {
        state.price = ''
      } else {
        // eslint-disable-next-line
        // state.price = state.quotDetail.price / * 算最大可买 / 影响购买价格 * /
      }
      purchaseValidation('卖出确认')
    }
    // (碎股取整)确定
    const brokenStock = () => {
      console.log('碎股取整')
      // 处理碎股将碎股全部取整 (-  -) => 取整之后就变成了自定义分配了
      state.allocationIndex = '2'
      state.tableData.forEach((v: any) => {
        v.num =
          v.num % state.buyHand === 0 ? v.num : v.num - (v.num % state.buyHand)
      })
      state.dialogVisible = false
    }
    // 买入卖出的方法
    const placeOrderBatchParams = ref({})
    const { mutate: placeOrderFunc } = useMutation(gql.placeOrderBatch)
    // 买入(卖出)确认
    const buyNow = () => {
      state.buyLoading = true
      const buyNowTimer = setTimeout(() => {
        state.buyLoading = false
        state.confirmPopupFlag = false
        nextTick(() => {
          state.timeoutDialogFlag = true
        })
      }, 30 * 1000)
      if (state.currentDisabled && !route.query?.ayers) {
        updateOrder()
        return
      }
      if (state.currentDisabled && route.query?.ayers) {
        updateAyersOrder({
          params: {
            fundAccount: state.tradingData[0].fundAccount,
            orderTxnReference: state.orderid,
            newEntrustAmount: parseInt(state.num),
            newEntrustPrice: parseFloat(state.price)
          }
        })
        return
      }
      const arr = state.tradingData.map((v: any) => ({
        fundAccount: v.fundAccount,
        entrustAmount: Number(v.num)
      }))
      const entrustBs = state.buyTitle === '买入确认' ? 'bid' : 'ask'
      placeOrderBatchParams.value = {
        params: {
          // fundAccount: JSON.parse(
          //   storage.rcGetItem(StorageType.local, 'userInfo')
          // ), // 账户
          exchangeType: state.obj.icon, // 港股 HK 美股 US
          stockCode: state.obj.code, // 股票代码
          entrustBs: entrustBs, // bid 买入 ask 卖出
          entrustPrice: Number(state.price), // 价格
          entrustProp: state.currentItem.value, // MO 市价单 LO 现价单
          checkFlag: '', // 空
          orderinfo: arr,
          extendedTrading: state.preMarketFlag
        }
      }
      placeOrderFunc(placeOrderBatchParams.value).then((result: any) => {
        const { lpoaPlaceOrderBatch } = result.data
        clearTimeout(buyNowTimer)
        if (lpoaPlaceOrderBatch.ok) {
          ElMessage({
            message: '订单已提交',
            type: 'success',
            customClass: 'custom-success'
          })
          state.buyLoading = false
          state.confirmPopupFlag = false
          // 清除数据
          clearInput()
          // 刷新列表数据
          refetch()
          return
        }
        state.buyLoading = false
        ElMessage({
          message: lpoaPlaceOrderBatch.reason.desc,
          type: 'error',
          customClass: 'custom-error'
        })
      })
    }
    // 购买 分配方式
    const allocation = (val: string) => {
      state.allocationIndex = val
      if (val === '0') {
        averageComputed()
        state.promptTitle = '平均分配'
      } else if (val === '1') {
        assetsComputed()
        state.promptTitle = '资产分配'
      } else {
        state.promptTitle = '自定义分配'
      }
    }
    // 选择买手-> 卖手
    const selectSeller = (item: any) => {
      // 市价单 竞价市价单 选中无效
      if (
        state.currentItem.value === 'MO' ||
        state.currentItem.value === 'AMO'
      ) {
        return
      }
      state.price = item.price
    }
    const hidePanel = () => {
      state.typeFlag = false
    }
    const show = () => {
      state.typeFlag = true
      document.addEventListener('click', hidePanel, false)
    }
    const hide = () => {
      state.typeFlag = false
      document.addEventListener('click', hidePanel, false)
    }
    // 选择类型
    const changeType = (event: any) => {
      event || (event = window.event)
      event.stopPropagation = event.stopPropagation
        ? event.stopPropagation()
        : event.cancelBubble
      state.typeFlag ? hide() : show()
    }
    // 排序重新计算分配方式
    const sortTable = () => {
      nextTick(() => {
        if (state.promptTitle === '平均分配') {
          averageComputed()
        } else if (state.promptTitle === '资产分配') {
          assetsComputed()
        }
      })
    }
    onMounted(() => {
      init()
    })
    onBeforeUnmount(() => {
      hide()
    })
    onBeforeRouteLeave((to, from, next) => {
      // 关闭mqtt
      clearInput()
      next()
    })
    // 改单
    const changOrderForm = (val: any) => {
      console.log(val)
      clearInput()
      let hkArr = {} as any
      if (val.exchangeType === 'HK') {
        hkArr = JSON.parse(JSON.stringify(listHK)).filter(
          (v: any) => v.value === val.entrustProp
        )
      } else {
        hkArr = JSON.parse(JSON.stringify(listUS)).filter(
          (v: any) => v.value === val.entrustProp
        )
      }
      // 更改选项数组
      if (val.exchangeType === 'HK') {
        state.list = listHK
        state.currentItem = hkArr[0]
      } else {
        state.list = listUS
        state.currentItem = hkArr[0]
      }
      if (
        state.currentItem.valueOf === 'MO1' ||
        state.currentItem.valueOf === 'AMO1' ||
        state.currentItem.valueOf === 'MO2'
      ) {
        state.marketFlag = false
      } else {
        state.marketFlag = true
      }
      state.currentDisabled = true
      state.sellDirection = val.entrustBs
      state.preMarketFlag = val.extendedTrading === '允许'
      const code = `${val.stockCode}.${val.exchangeType}`
      if (val.stockCode && val.exchangeType) {
        const params = {
          id: code,
          mkt: val.exchangeType,
          code: val.stockCode
        }
        state.obj = {
          code: val.stockCode,
          icon: val.exchangeType,
          label: val.stockNameGb,
          value: ''
        }
        state.stock = code
        console.log(params)
        // eslint-disable-next-line
        queryQuot(params)
        state.entrustPrice = val.entrustPrice
      }
      state.num = val.entrustAmount
    }
    // 确认改单
    const confirmChangeOrder = () => {
      if (
        Number(state.price) === 0 &&
        state.currentItem.value !== 'MO' &&
        state.currentItem.value !== 'AMO'
      ) {
        ElMessage({
          message: '价格不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (Number(state.num) === 0) {
        ElMessage({
          message: '数量不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      state.tradingObj = {
        entrustBs: state.sellDirection,
        stockName: state.obj.label,
        stockCode: state.obj.code,
        price: state.price,
        entrustAmount: state.num,
        exchangeType: state.obj.icon,
        entrustProp: ENTRUSTPROPMAP[state.currentItem.value],
        extendedTrading: state.preMarketFlag
      }
      state.tradingData = state.tableData.filter(
        (item: any) => item.selectStatus
      )
      state.confirmPopupFlag = true
    }
    const showUS = computed(() => state.obj.icon === 'US' && state.obj.label)
    const inputNum = () => {
      state.isRecalculate = true
    }
    // 是否展示延迟行情
    const showExtendedQuotationText = computed(() => {
      const extendedQuotation = storage.rcGetItem(StorageType.local, 'extendedQuotation') || 'false'
      return JSON.parse(extendedQuotation)
    })

    // 获取topic
    const getTopic = (state: any) => {
      const isDelayQuot = showExtendedQuotationText.value

      let topic2 = `QUOT.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.2`
      let topic3 = `QUOT.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.3`
      let topic21 = `QUOT.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.21`
      let topic2ByML = `QUOT.ML.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.2`
      let topic3ByML = `QUOT.ML.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.3`

      if (isDelayQuot) {
        topic2 = `DELAY.QUOT.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.2`
        topic3 = `DELAY.QUOT.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.3`
        topic21 = `DELAY.QUOT.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.21`
        topic2ByML = `DELAY.QUOT.ML.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.2`
        topic3ByML = `DELAY.QUOT.ML.${state.mktCode.mkt}.${state.mktCode.code}.${state.mktCode.mkt}.3`
      }

      return {
        topic2,
        topic3,
        topic21,
        topic2ByML,
        topic3ByML
      }
    }

    return {
      ...toRefs(state),
      tHeaderList,
      changeSelect,
      inputStock,
      changeType,
      changeItem,
      selectItem,
      clearInput,
      brokenStock,
      up,
      info,
      ok1,
      ok2,
      show,
      hide,
      hidePanel,
      handlerClick,
      change,
      bulkBuy,
      batchSell,
      allocation,
      selectSeller,
      bulkBuyList,
      checkAll,
      buyNow,
      amountComputed,
      computedRiseFall,
      numFormatK,
      numFormat,
      autoColor,
      inputLoading,
      Decimal,
      keydown,
      inputNumRef,
      refPrice,
      computedChangeColor,
      sortTable,
      changOrderForm,
      confirmChangeOrder,
      confirm,
      changePreMkt,
      maxBuy,
      maxSell,
      showUS,
      inputNum,
      showExtendedQuotationText
    }
  }
})
